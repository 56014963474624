<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">Which of the following are <b>ionic</b> compounds? Select all that apply.</p>

      <div v-for="option in options" :key="option.value" class="pl-8">
        <v-checkbox
          v-model="inputs.input1"
          class="d-inline-block my-0 py-0"
          :value="option.value"
        />
        <chemical-latex :content="option.molecule" />
      </div>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';

export default {
  name: 'Question55',
  components: {ChemicalLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {input1: []},
      options: [
        {molecule: 'SO2', value: '1'},
        {molecule: 'BrCl', value: '2'},
        {molecule: 'NH3', value: '3'},
        {molecule: 'SrO', value: '4'},
        {molecule: 'NH4Cl', value: '5'},
        {molecule: 'LiBr', value: '6'},
      ],
    };
  },
};
</script>
